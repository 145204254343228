const topMain = () => {
  const pageTop = (document.getElementById("pageTop") != null);
  if(pageTop){

    let elem = document.getElementById('jsMainImg');

    elem.classList.add('act');
    
  
  }
};
export default topMain;