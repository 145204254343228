const conceptGallery = (() => {

  const pageConcept = (document.getElementById("pageConcept") != null);
  if (pageConcept) {

    const fadeElem = document.querySelectorAll('.js-gallery-img');

    gsap.set(fadeElem, { opacity: 0, scale: 1.1, });

    gsap.to(fadeElem, {
      opacity: 1,
      scale: 1,
      duration: 1.5,
      scrollTrigger: {
        trigger: '.js-gallery-box',
        start: 'top 80%',
      },
      stagger: {
        from: "random",
        amount: 1,
      }
    });

  }

})();
export default conceptGallery;