import { scrollControlFunc, windowResize, topMain } from './index';

const loadFunc = (() => {

  let loadWrap = document.getElementById('jsLoadWrap');
  scrollControlFunc.noScroll();
  windowResize();

  const startFunc = () => {
    loadWrap.classList.add('no-act');
    scrollControlFunc.returnScroll();
    topMain();
  }

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    let video = document.getElementById('jsBgVideo');
    video.load()
    video.addEventListener('loadedmetadata', function () {
      video.play()
      setTimeout(startFunc, 100)
    });

  } else {
    document.addEventListener("DOMContentLoaded", () => {
      setTimeout(startFunc, 100)
    });
  }


})();
export default loadFunc;