const navFunc = (() => {

  let nav = document.getElementById('jsNav');
  let navBtn = document.getElementById('jsNavBtn');
  let bg = nav.querySelector('.jsNavBg');

  const toggleClass = () => {
    if (navBtn.classList.contains('act') == true) {
      nav.classList.remove('act');
      navBtn.classList.remove('act');
    } else {
      nav.classList.add('act');
      navBtn.classList.add('act');
    }
  }

  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    toggleClass()
  });
  bg.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    toggleClass()
  });

})();
export default navFunc;